import React, { useEffect, useRef } from 'react'
import * as d3 from "d3"

const dummyData1 = [{x : "May", y : 0}, {x : "june", y : 0}, {x : "july", y : 0}, {x : "Aug", y : 0}, {x : "Sept", y : 0}, {x : "Oct", y : 0}, {x : "Nov", y : 2}]

function BarChart({ xdim, ydim, margin, data1 = dummyData1}) {

  const canvas = useRef(null);
  console.log(data1, "xData yData", dummyData1)

  useEffect(() => {
    const svg = d3.select("#barChart")
    
    data1.reverse()

    let hvalues = data1.map(obj => obj.x)
    let xScale = d3.scaleBand()
                    .domain(hvalues)
                    .range([margin.left, xdim - margin.left])
                    .padding(0.1)

    let formatxAxis = d3.format('.0f');

    let values = data1.map(obj => obj.y)
    console.log("barr", values)
    let yScale = d3.scaleLinear()
                  .domain([0, 10])
                  .range([ydim, 0])

    let xAxis = d3.axisBottom(xScale)
    svg.append("g").attr("transform", `translate(0, ${ydim + margin.top})`).call(xAxis)
    let yAxis = d3.axisLeft(yScale).tickFormat(formatxAxis).ticks(8)
    svg.append("g").attr("transform", `translate(${margin.left}, ${margin.top})`).call(yAxis)

    svg.selectAll("rect")
    //Todo : Change to data1 after logic is revised.
    .data(dummyData1)
    .enter()
    .append("rect")
    .attr("width", xScale.bandwidth())
    .attr("height", (d) => {
      console.log("rect height", ydim - yScale(d.y))
      return ydim - yScale(d.y)
    })
    .attr("x", 0)
    .attr("y", (d) => {
      return margin.top + yScale(d.y)
    })
    .style("opacity", 0)
    .attr("fill", "#55819466")
    .attr("stroke-opacity", 0.1)
    .attr("rx", 8)
    .transition()
    .attr("x", (d, i) => {
      return xScale(d.x) 
    })
    .style("opacity", 1)
    .duration(1000)

  }, [])

  return (
    <div style={{width: '100%', height: "100%", background: 'white', margin: "auto"}}>
      <svg
        viewBox={`0 0 ${xdim + margin.left + margin.right} ${ydim + margin.top + margin.bottom}`}
        // preserveAspectRatio="XMinYMin"
        width="100%"
        height="100%"
        ref={canvas}
        id = "barChart"
      >

      </svg>
    </div>
  )
}

export default BarChart