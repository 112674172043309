import React from 'react'
import * as d3 from "d3"
import { useEffect, useRef } from 'react';
import moment from "moment"
import "./charts.css"
import { MIN_THRESHOLD_VALUE } from 'constants/enums/constant';

    
function PredictChart({padding = 0, bgColor = "white", projectionData , dateRange, predictionData }) {

console.log(predictionData)
  const wrapper = useRef(null)
  useEffect(() => {

    let height = wrapper.current.clientHeight
    let width = wrapper.current.clientWidth
    let spacing = 50
    let elevatexAxis = 40

    // let data = details.map((item, index) => {
    //     if(index%100 === 0){
    //       return item
    //     }
    // })

    let data = projectionData.filter(item => item !== undefined)
    data.sort((a,b) => new Date(a.datetime) - new Date(b.datetime))
    let xDate = data.map(item => new Date(item.datetime))
    let yResis = data.map(item => item.resistance)
    const resisMaxProjection = yResis.sort((a,b) => b-a)[0]
    let combinedData = data.map(item => {
      return {
        x : new Date(item.datetime),
        y : item?.resistance 
      }
    })

    let count = 0
    //predict data
    predictionData = predictionData?.filter(item => item !== undefined)
    predictionData.sort((a,b) => new Date(a.datetime) - new Date(b.datetime))
    predictionData = predictionData?.map((item) => {
        if( moment(item.datetime, 'YYYY-MM-DD')._d >= moment()._d){
            if(count < 366){
                count++
                return {
                    x : moment(item.datetime, 'YYYY-MM-DD')._d,
                    y : item?.resistance_worst,
                    s : item?.resistance_best
                  }
            }
        }
    })
    predictionData = predictionData?.filter(item => item !== undefined)
    console.log(predictionData, "**********************")

    let resisWorstPrediction = predictionData?.map(item => item.y)
    let resisWorstMaxPrediction = resisWorstPrediction.sort((a,b) => b-a)[0]
    

    // Combining last and first data points of combined data and predict data
    if(combinedData.length > 0){
        predictionData[0].y  = combinedData[combinedData.length-1].y
        predictionData[0].x  = combinedData[combinedData.length-1].x
    }

    let orangeLine = []
    let redLine = []
    let greenLine = []
    let smallResistance = []
    let resistanceLine = []
    let firstBreak = null
    let secondBreak = null
    let threshold = []

    if(predictionData){
        for(let item of predictionData){
            if(!firstBreak){
                if(item.y > MIN_THRESHOLD_VALUE){
                    firstBreak = item
                    orangeLine.push(firstBreak)
                    if(greenLine.length > 0){
                        orangeLine.unshift(greenLine[greenLine.length - 1])
                    }
                    continue
                }
                greenLine.push(item)
            }
            
            if(firstBreak && !secondBreak){

                if(item.s > MIN_THRESHOLD_VALUE){
                    secondBreak = item
                    redLine.push(secondBreak)
                    if(orangeLine.length > 0){
                        redLine.unshift(orangeLine[orangeLine.length - 1])
                    }
                    continue
                }
                orangeLine.push(item)
            }

            if(firstBreak && secondBreak){
                redLine.push(item)
            }

            if(redLine.length > 9){
                break
            }
        }

        smallResistance.push({
            x : predictionData[0].x,
            y : predictionData[0].y 
        })
        for(let i = 1 ; i < orangeLine.length + greenLine.length + 9; i++){
            if(!predictionData[i]){
                break
            }
            smallResistance.push({
                x : predictionData[i].x,
                y : predictionData[i].s
            })
        }

        console.log("green", greenLine,"orange", orangeLine, "red", redLine)


        for(let i = 0 ; i < orangeLine.length + greenLine.length + 9; i++){
            if(!predictionData[i]){
                break
            }
            resistanceLine.push({
                x : predictionData[i].x,
                y : predictionData[i].y
            })
        }
    }
    
    let xDataPrediction = predictionData.map(item => new Date(item.x))
    let xSmallResistance = smallResistance.map(item => new Date(item.x))

    if(predictionData ){
        
        if(xDate.length > 0 ){
            for(let i=0; i< xDate.length; i++){
                threshold.push({
                    x : xDate[i],
                    y : MIN_THRESHOLD_VALUE
                })
            }
        }
        
        if(xSmallResistance.length > 0){
            for(let i=0; i < xSmallResistance.length; i++){
                threshold.push({
                    x : xSmallResistance[i],
                    y : MIN_THRESHOLD_VALUE
                })
            }
        }
        
    }

    // const getTickInterval = () => {
    //   return  dateRange <= 55 ? dateRange <= 31 
    //           ? dateRange <= 8 
    //           ? d3.timeDay.every(1) : 
    //           d3.timeDay.every(3) : 
    //           d3.timeWeek.every(1) :
    //           d3.timeWeek.every(2)
    // }
    let svg = d3.select(".predict_chart").append("svg").attr("width", "100%").attr("height", "100%").style("background-color", bgColor).style("padding", padding)
     
    let defs = svg.append("defs");
        
    let xScale = d3.scaleTime()
                          .domain([d3.min(xDate), d3.max(xSmallResistance)])
                          .range([spacing, width - spacing])


    let yScale = d3.scaleLinear()
                    .domain([0, d3.max([resisMaxProjection, resisWorstMaxPrediction])])
                    .range([height - spacing, elevatexAxis])

    //x label
    svg
    .append("text")
    .attr("class", "x label")
    .attr("text-anchor", "end")
    .attr("x", width / 1.88)
    .attr("y", height - elevatexAxis + 32)
    .text("Date")
    .style("font-size", "12px")
    .style("font-weight", "100");

  //y label
  svg
    .append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("y", 7)
    .attr("x", -height / 2 + 10)
    .attr("dy", ".75em")
    .attr("transform", "rotate(-90)")
    .text("Resistance (mΩ)")
    .style("font-size", "12px")
    .style("font-weight", "100");

    var focusText = svg
    .append("g")
    .append("text")
    .style("opacity", 0)
    .attr("text-anchor", "left")
    .attr("alignment-baseline", "middle");

    //legends
    let legendData = [
        {grade : "Historical", class : "normal", number : 2, color : "url(#svgGradient)"},
        {grade : "Transition", class : "fieldAged", number : 2, color : "#60C610"},
        {grade : "Intervention", class : "normal", number : 2, color : "#F2A541"},
        {grade : "Fault Zone", class : "crossmated", number : 2, color : "#F04B4B"},
        ]

    let legends = svg.append("g").attr("transform", `translate(-${.03*width}, ${.05*height})`).selectAll(".legends").data(legendData)
    let legendGroup = legends.enter().append("g").classed("legends", true).attr("transform", (d, i) => `translate(${(i+1)*.2*width}, 0)`)


    legendGroup.append("rect")
    .attr("width", 24)
    .attr("height", 17)
    .attr("fill", (d) => d.color)
    .style("rx", 5)

    legendGroup.append("text")
                  .text((d) => d.grade)
                  .attr("x", 35)
                  .attr("y", 15)
                  .style("fill", "#000") //.attr will also work
                //   .style("font-weight", "700")
                  .style("font-size", 18)


    let xAxis = d3.axisBottom(xScale).ticks(d3.timeMonth.every()).tickFormat((d) => String(d).slice(4,15))
    svg.append("g").classed("axis", true).attr("transform", `translate(0,${height-elevatexAxis})`).call(xAxis)
    let yAxis = d3.axisLeft(yScale).ticks(8)
    svg.append("g").classed("axis", true).classed("yaxis", true).attr("transform", `translate(${spacing},${spacing-elevatexAxis})`).call(yAxis)

    let area = function(datum, boolean) {
        return d3.area()
        .x(d => boolean ? xScale(d.x) : xScale(datum[0]?.x))
        .y1(d => yScale(d.y) + spacing - elevatexAxis)
        .y0(height - elevatexAxis - 2)
        .curve(d3.curveBasis)
        (datum)
    }
    let timeConstant = 6

    let path = svg.append("path")
    .attr("transform", `translate(1,0)`)
    .data([combinedData])
    .attr("d", d => area(d, false))
    .classed("area-blue", true)
    .style("fill", "rgba(85,129,148, .5)")
    .transition()
    .duration(combinedData.length*timeConstant)
    .attr("d", d => area(d, true))
    .style("stroke", "url(#svgGradient)")
    .style("stroke-width",1)
    .ease(d3.easeLinear)

    let green = svg.append("path")
    .attr("transform", `translate(1,0)`)
    .data([greenLine])
    .attr("d", d => area(d, false))
    .classed("area-green", true)
    .style("fill", "#60C610")
    .transition()
    .delay(combinedData.length*timeConstant)
    .duration(greenLine.length*timeConstant)
    .ease(d3.easeLinear)
    .attr("d", d => area(d, true))
    .style("stroke", "#60C610")
    .style("stroke-width",1)


    let orange = svg.append("path")
    .attr("transform", `translate(1,0)`)
    .data([orangeLine])
    .classed("area-orange", true)
    .attr("d", d => area(d, false))
    .style("fill", "#F2A541")
    .transition()
    .delay(combinedData.length*timeConstant + greenLine.length*timeConstant)
    .duration(orangeLine.length*timeConstant)
    .ease(d3.easeLinear)
    .attr("d", d => area(d, true))
    .style("stroke", "#F2A541")
    .style("stroke-width",1)
    .style("posotion", "relative")

    let red = svg.append("path")
    .attr("transform", `translate(1,0)`)
    .data([redLine])
    .attr("d",  d => area(d, false))
    .classed("area-red", true)
    .style("fill", "#F04B4B")
    .transition()
    .delay(combinedData.length*timeConstant + greenLine.length*timeConstant + orangeLine.length*timeConstant)
    .duration(redLine.length*timeConstant)
    .ease(d3.easeLinear)
    .attr("d", d => area(d, true))
    .style("stroke", "#F04B4B")
    .style("stroke-width",1)

    let line1 = d3.line().x(d => xScale(d.x)).y(d => yScale(d.y) + spacing - elevatexAxis).curve(d3.curveBasis)
    let smallResistanceLine = svg.append("path")
    .attr("transform", `translate(1,0)`)
    .attr("d", line1(smallResistance))
    .style("fill", "none")
    .style("stroke", "blue")
    .style("stroke-width",2)
    const pathLengthSmall = smallResistanceLine.node().getTotalLength();   

    let resistancePath = svg.append("path")
    .attr("transform", `translate(1,0)`)
    .attr("d", line1(resistanceLine))
    .style("fill", "none")
    .style("stroke", "red")
    .style("stroke-width",2)
    const pathLengthResistance = resistancePath.node().getTotalLength();    

    let thresholdPath = svg.append("path")
    .style("stroke-dasharray",("5,5"))
    .attr("transform", `translate(1,0)`)
    .attr("d", line1(threshold))
    .style("fill", "none")
    .style("stroke", "#C1D4E0")
    .style("stroke-width",2)
    const thresholdvaluePath = thresholdPath.node().getTotalLength();   
    
    // green
    // .transition()
    // .duration(10000)
    // .attr("d", green.d)

    // path
    // .attr("stroke-dashoffset", pathLength)
    // .attr("stroke-dasharray", pathLength)
    // .transition()
    // .duration(2500)
    // .attr("stroke-dashoffset", 0);

    // orange
    // .attr("stroke-dashoffset", pathLengthOrange)
    // .attr("stroke-dasharray", pathLengthOrange)
    // .transition()
    // .delay(2500)
    // .duration(2500)
    // .attr("stroke-dashoffset", 0);

    // red
    // .attr("stroke-dashoffset", pathLengthRed)
    // .attr("stroke-dasharray", pathLengthRed)
    // .transition()
    // .delay(orangeLine.length > 0 ? 5000 : 2500)
    // .duration(2500)
    // .attr("stroke-dashoffset", 0);

    const getDashedPath = (totalLength) => {

        let dashing = "5, 5"

        let dashLength =
        dashing
            .split(/[\s,]/)
            .map(function (a) { return parseFloat(a) || 0 })
            .reduce(function (a, b) { return a + b });

        let dashCount = Math.ceil( totalLength / dashLength );

        let newDashes = new Array(dashCount).join( dashing + " " );

        return newDashes + " 0, " + totalLength
    }

    resistancePath
    .attr("stroke-dashoffset", pathLengthResistance )
    .attr("stroke-dasharray", getDashedPath(pathLengthResistance))
    .transition()
    // .delay(combinedData.length*timeConstant + greenLine.length*timeConstant + orangeLine.length*timeConstant)
    .duration(combinedData.length*timeConstant + greenLine.length*timeConstant + orangeLine.length*timeConstant)
    .attr("stroke-dashoffset", 0);
    

    smallResistanceLine
    .attr("stroke-dashoffset", pathLengthSmall)
    .attr("stroke-dasharray", getDashedPath(pathLengthSmall))
    .transition()
    // .delay(combinedData.length*timeConstant + greenLine.length*timeConstant + orangeLine.length*timeConstant)
    .duration(combinedData.length*timeConstant + greenLine.length*timeConstant + orangeLine.length*timeConstant)
    .attr("stroke-dashoffset", 0);


    //remove axis lines and ticks
    // d3.selectAll(".axis path").remove()
    // d3.selectAll(".axis g line").attr("stroke", "#c6c9cb")
    // d3.select(".yaxis g:nth-child(9) line").style("x2", "100px")

    /* --------- Areas Hover ---------*/ 

    const getToolTipName = (color) => {
        switch(color){
            case "blue":
                return "Historical"
            case "green":
                return "Transition"
            case "orange":
                return "Intervention"
            case "red":
                return "Fault Zone"
        }
    }

    for(let color of ["red", "green", "blue", "orange"]){
        d3.select(`.area-${color}`)
        .on("mousedown", (e, d) => {
            let x0 = d3.pointer(e)[0];
            let y0 = d3.pointer(e)[1];
            
            d3.select(".status_modal .modal-body")
            .append("div")
            .style("left", x0 + "px")
            .style("top", y0 - 40 + "px")
            .classed("tool-tip", true)
            .append("p")
            .html(getToolTipName(color))

        })
        .on("mouseup", () => {
            d3.select(".tool-tip").remove()
        })
    }
    
  },[predictionData, projectionData])

  return (
    <div ref={wrapper} style={{height : "100%", width : "100%"}} className="predict_chart"></div>
  )
}

export default PredictChart