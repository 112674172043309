import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PieChart from "components/Charts/PieChart";
import BarChart from "components/Charts/BarChart";
import WorldMap from "components/Charts/WorldMap";
import { getUser } from "store/App/AppReducer";
import OuterBox from "pages/ConnectorsListPage/Components/OuterBox";
import ProgressBar from "pages/ConnectorsListPage/Components/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { getConnectorTypeList, getDashboardData, stateChange } from "store/Dashboard/DashboardActions";
import { getChangedConnector, getConnectorTypeResult, getDashboardDataResult } from "store/Dashboard/DashboardReducer";

const ConnectorsListPage = () => {
  let user = useSelector((state) => getUser(state));
  const dispatch = useDispatch();
  const [barChartData, setBarChartData] = useState(null)
  const dashboardData = useSelector(state => getDashboardDataResult(state))
  const [pieData, setPieData] = useState(null)
  const connectorTypeList = useSelector(state => getConnectorTypeResult(state))
  const changedConnector = useSelector(state => getChangedConnector(state))

  const getBarChartData = () => {
    const monthsMap = {
      1 : "Jan",
      2 : "Feb",
      3 : "Mar",
      4 : "Apr",
      5 : "May",
      6 : "June",
      7 : "July",
      8 : "Aug",
      9 : "Sep",
      10 : "Oct",
      11 : "Nov",
      12 : "Dec",
    };

    const monthWiseData = dashboardData?.last_months_data?.map(month => ({
        x : monthsMap[month.month] , y :  Number(month.count),
      }));
    console.log(monthWiseData, "*********")
    return monthWiseData;
  }


  useEffect(() => {
    dispatch(getDashboardData())
    dispatch(getConnectorTypeList())
  }, [])

  useEffect(() => {
    setBarChartData(getBarChartData())
    setPieData(dashboardData?.status_counts)
  }, [dashboardData])

  console.log(barChartData, "****")

  return (  
    <div className="row portfolio-row">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio | SmartHelio</title>
      </Helmet>
      <h2 className="home_heading">Welcome {user.contact_name}</h2>
      <div className="grid-home small-box">
        <div className="left-side-box">
          {/* <div className="d-flex">
            <OuterBox
              height={"100%"}
              icon={
                "https://ik.imagekit.io/rzlzf40okya/smarthelio/emojione_sun.svg"
              }
              text={"92K"}
              subtext={"Total Data Points Collected "}
            />
            <OuterBox
              height={"100%"}
              icon={
                "https://ik.imagekit.io/rzlzf40okya/smarthelio/close_ic.svg"
              }
              // text={"₹12"}
              text={"6"}
              subtext={"Faulty Connectors Detected"}
            />
          </div> */}
          <div style={{ height: "99%"}}>
            <OuterBox
              height={"48%"}
              width={"97%"}
              icon={
                "https://ik.imagekit.io/rzlzf40okya/smarthelio/white_global_icon.svg"
              }
              text={dashboardData?.faulty_device_count}
              subtext={"Faulty Connectors Detected"}
            />
         
            <OuterBox
              width={"97%"}
              height={"48%"}
              icon={
                "https://ik.imagekit.io/rzlzf40okya/smarthelio/man_icon.svg"
              }
              text={dashboardData?.connectors_failing_coming_thirty_days_count}
              subtext={"Connectors Failing in 30 Days"}
            />
          </div>
        </div>
        <div className="white_box" style={{ height: "100%"}}>
            <div className="" style={{ height: "100%" }}>
              {/*<h3>Connectors</h3>*/}
              {/*<h1>8</h1>*/}
              <div style={{height : "100%"}}>
                {pieData && <PieChart
                  pieData = {pieData}
                  bg={"white"}
                  padAngle={0}
                  padRadius={0}
                  cornerRadius={0}
                />
                }
              </div>
            </div>
           
          </div>
       
       </div>
        <div className="right-side-box mt-4" style={{height : "52vh"}}>
        
        <div className="white_box" style = {{height : "100%"}}>
          <div className="d-flex border-bottom align-items-center pb-3">
          <img
                src="https://ik.imagekit.io/rzlzf40okya/smarthelio/faults_black.svg"
                className="mr-2"
              />
            <h6 style={{ margin: "0.27rem" }}>Faults Detected</h6>
          </div>
            <div style={{ height: "100%" }}>
              {barChartData && <BarChart xdim={750}
                        ydim={400}
                        margin={{
                          top: 0, bottom: 80, left: 20, right: 20
                        }}
                        data1 = {barChartData}
              />}
            </div>
          </div>
          <div className="white_box" style = {{height : "100%"}}>
            <div className="d-flex border-bottom align-items-center pb-3">
              <img
                src="https://ik.imagekit.io/rzlzf40okya/smarthelio/drag.svg"
                className="mr-2"
              />
              <h6 style={{ margin: 0 }}>Global Presence of Connectors</h6>
            </div>
            <div style={{ height: "73%" }}>
              {dashboardData && <WorldMap locations={dashboardData?.count_by_address}/>}
            </div>
            <div style={{marginTop : "2%", marginBottom:"2%"}}>
              {dashboardData && <ProgressBar locations={dashboardData?.count_by_address}/>}
            </div>
          </div>
        </div>
        
      
    </div>
  );
};

export default ConnectorsListPage;
