import DeviceTable from '../../components/DeviceTable/DeviceTable';
import {Helmet} from "react-helmet";
import React from 'react';
import connectorDevices from '../../content/connectorDevices.json';
import Tables from 'components/Tables/Tables';
const DevicesListPage = () => {
  
    return (
       
            <div className='row portfolio-row'>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Devices | IOT Platform</title>
                </Helmet>
                {connectorDevices.length > 0 && (
                    <DeviceTable
                        connectorDevices={connectorDevices}
                    />
                    
                )}
            </div>

       
    )
}

export default DevicesListPage;
