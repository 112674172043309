import React, { useEffect, useRef,useState } from 'react'
import * as d3 from "d3"
import Box from "konnect/Box";

function PieChart({bg = "lightblue",
                  pieData, 
                  useGradientScale, 
                  gradientColors = ["lightblue", "blue"],
                  padAngle = 0.1,
                  padRadius = 0.1,
                  cornerRadius = 0
                }) {

  const wrapper = useRef(null)

  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    let height = wrapper.current.clientHeight
    let width = wrapper.current.clientWidth
    let outerRadius = .36*height
    let innerRadius = outerRadius/1.15
    let svg = d3.select(".pie-chart").append("svg").attr("width", "100%").attr("height", "100%").style("background-color", bg)

    let details = [
                  {grade : "Normal", class : "normal", number : 0, color : "#60c610"},
                  {grade : "Faulty", class : "faulty", number : 0, color : "#F2A541"},
                  {grade : "Hazardous", class : "hazardous", number : 0, color : "#F04B4B"},
                  ]


    let sum = 0
    details = details.map(data => {
      const result = pieData.filter(pie => pie?._id.current_status == data.class)
      if(result.length > 0){
        data.number = result[0].count
      }

      sum = sum + data.number
      return data
    })

    setTotalCount(sum)
    
    let data = d3.pie().sort(null).value(d => d.number)(details)

    // console.log("pie chart data", data)

    // scale 1
    let gradientScale = d3.scaleLinear()
    .domain([0, 50])
    .range(gradientColors)
    
    let pieSegment = d3.arc()
                 .innerRadius(innerRadius)
                 .outerRadius(outerRadius)
                 .padAngle(padAngle)  //padAngle and padRadius are multiplied for adding padding between sections
                 .padRadius(padRadius)
                //.cornerRadius(cornerRadius)
   
    let segments = svg.append("g").attr("transform", `translate(${width*.5}, ${height/2.5})`).selectAll("path").data(data)
    segments.enter().append("path")
    .attr("d", pieSegment)
    .style("fill", d => d.data.color)
    .style("cursor", "pointer")

    let pieSegment2 = d3.arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius + 10)
    .padAngle(padAngle)  //padAngle and padRadius are multiplied for adding padding between sections
    .padRadius(padRadius)

    let segments2 = svg.append("g").classed("hoverPie", true).attr("transform", `translate(${width*.5}, ${height/2.5})`).selectAll("path").data(data)
    segments2.enter().append("path")
    .attr("d", pieSegment2)
    .style("fill", d => d.data.color)
    .style("cursor", "pointer")
    .style("opacity", 0)
    .on("mouseover", function(e, d){
      d3.select(this)
      .transition()
      .duration(300)
      .style("opacity", 1)

      d3.select(`.centerText-${d.data.class}`)
      .transition()
      .duration(300)
      .style("opacity", 1)

    })
    .on("mouseout", function(e, d){
      d3.select(this)
      .transition()
      .duration(300)
      .style("opacity", 0)

      d3.select(`.centerText-${d.data.class}`)
      .transition()
      .duration(300)
      .style("opacity", 0)
    })

    let content = svg.select(".hoverPie").selectAll("text").data(data)
    content.enter().append("text")
    .attr("transform", (d) =>  `translate(${pieSegment2.centroid(d)[0]}, ${pieSegment2.centroid(d)[1] + 5})`)
    .text((d) => d.data.number)
    .style("stroke", "white")
    .style("fill", "white")
    .style("padding", 10)
    .attr("dx", "-2px")
    .classed("texts", true)
    .style("position", "relative")
    .style("opacity", 0)

   
    
    
    svg.selectAll(".texts").each(function(d) {
      d3.select(this)
      .attr("class", `centerText-${d.data.class}`)
    })
    
    //dx in attr is relative positioning in x direction

    let legends = svg.append("g").attr("transform", `translate(${.1*width}, ${.88*height})`).selectAll(".legends").data(data)
    let legendGroup = legends.enter().append("g").classed("legends", true).attr("transform", (d, i) => `translate(${(i)*.3*width}, 0)`)


    legendGroup.append("rect")
    .attr("width", 24)
    .attr("height", 17)
    .attr("fill", (d) => useGradientScale ? gradientScale(d.data.number) : d.data.color)
    .style("rx", 5)

    legendGroup.append("text")
                  .text((d) => d.data.grade)
                  .attr("x", 35)
                  .attr("y", 15)
                  .style("fill", "#000") //.attr will also work
                  .style("font-weight", "700")
                  .style("font-size", 18)
                  .style("padding", 18)


  },[])
  return (
    <div ref={wrapper} style={{height : "100%", width : "100%", position: "relative", display: "flex", justifyContent: "center", alignItems: "center"}} className="pie-chart">
      <div style={{position: "absolute", top:"20%"}}>
        <Box fontSize="16px" fontWeight={600}>Connectors</Box>
        <Box fontSize="75px" textAlign="center" fontWeight={700}>{totalCount}</Box>
      </div>
    </div>
  )
}

export default PieChart