
import React from "react";

const ProgressBar = ({locations}) => {
  return (
    <div className="progress_bar">
      {locations?.map(location => {
        return (
          <div style={{ marginRight: "35px" }}>
            <div
              className="d-flex justify-content-between"
              style={{ width: "135px" }}
            >
              <label for="file">{location?._id?.site_address}</label>
              <label>{location?.count} connectors</label>
            </div>
            <div className="progress_skill">
              <div className="skill1 html" style={{backgroundColor : "#000"}}></div>
            </div>
          </div>
        )
      })}
      
    </div>
  );
};

export default ProgressBar;
