import {combineReducers} from "redux";
import app from './App/AppReducer';
import dashboard from './Dashboard/DashboardReducer'
import connector from './ConnectorPage/ConnectorReducer'
import faultClassificationReducer from "./FaultClassificationPage/FaultClassificationReducer";

export default combineReducers({
    app,
    dashboard, //for dashboard apis
    connector,  //for individual connector page apis
    faultClassificationReducer //for fault classification page apis
})
