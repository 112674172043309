import React from 'react'

function OuterBox({height, width, icon = null , text = null, textIcon = null, subtext = null, children}) {
  return (
    <div className='white_box new-outer-box' style={{height, width }}>
       <div className='image_bg'> {icon ? <img src={icon} style={{height : "70px"}}/> : null}</div>
       <div className='new-outer-content'> {text ? <h1>{textIcon ? <img src={textIcon}/> : null} {text}</h1> : null}
        {subtext ? <h6>{subtext}</h6> : null}</div>
        {children}
    </div>
  )
}

export default OuterBox