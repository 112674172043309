import {
    SET_CONNECTOR_LIST,
    SET_CONNECTOR_LIST_LOADING,
    SET_CONNECTOR_LIST_LOADED
} from './FaultClassificationActions';


// const API_ALLOWED_STATES = {
//     NOT_SENT: 0, IN_PROGRESS: 1, IS_SUCCESS: 2, IS_FAILED: 3
// }

const initialState = {
    connectorList : null,
    // connectorApiState: API_ALLOWED_STATES.NOT_SENT, 
    isConnectorListLoaded : false,
    isConnectorListLoading : false
}

const faultClassificationReducer = (state = initialState, action={}) =>{
    switch (action.type) {
        
        case SET_CONNECTOR_LIST_LOADING:
            return {
                ...state,
                isConnectorListLoading: action.status,
            }

        case SET_CONNECTOR_LIST :
            return {
                ...state,
                connectorList : action.data
                }
        case SET_CONNECTOR_LIST_LOADED :
            return {
                ...state,
                isConnectorListLoaded : action.status
                }
            
        default:
            return state;
    }
}

/**selectors */
export const getConnectorListLoading = state => state.faultClassificationReducer.isConnectorListLoading
export const getConnectorListResult = state => state.faultClassificationReducer.connectorList
export const getConnectorListLoaded = state => state.faultClassificationReducer.isConnectorListLoaded


export default faultClassificationReducer;
