import React from 'react';
import { CircularProgress } from '@mui/material';
import './fullPageLoader.css';

function FullPageLoader() {
  return (
    <div className="loader-wrapper">
        <div>
            <CircularProgress color="secondary" size="100" />
        </div>
    </div>
  );
}

export default FullPageLoader;
