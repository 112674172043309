import fetch from "isomorphic-fetch";

const API_URL = process.env.REACT_APP_API_URL || `https://iot-apidev.smarthelio.cloud`;
// const API_URL = `http://localhost:8000`;
const LAMBDA_API_URL = process.env.REACT_APP_LAMBDA_URL || 'https://w5pql8fau3.execute-api.us-east-1.amazonaws.com/develop';

export default function callApi(endpoint, method = "get", body) {
    let baseURL = '';
    const token = typeof window === "undefined" ? "" : window.localStorage.getItem("token");
    const headers = {};

    baseURL = API_URL;
    if (token && token !== "") {
        headers.Authorization = `Bearer ${token}`
    }

    headers['Content-Type'] = "application/json";

    return fetch(`${baseURL}/${endpoint}`, {
        headers,
        method,
        body: body ? JSON.stringify(body) : undefined
    })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
        if (!response.ok) {
            return Promise.reject(json);
        }
        return json;
    })
    .then(
        response => response,
        error => error
    );
}

export function LambdaCallApi(endpoint, method = "get", body = {}) {
    let baseURL = '';
    const headers = {};
    baseURL = LAMBDA_API_URL;

    headers['Content-Type'] = "application/json";

    return fetch(`${baseURL}/${endpoint}`, {
        headers,
        method,
        body: body ? JSON.stringify(body) : undefined
    })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
        if (!response.ok) {
            return Promise.reject(json);
        }
        return json;
    })
    .then(
        response => response,
        error => error
    );
}
