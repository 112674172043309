import callApi, { LambdaCallApi } from "../../util/apiCaller";
import { errorMsg, successMsg } from "util/toast";
import moment from "moment";

export const SET_PREDICTION_DATA = "SET_PREDICTION_DATA"
export const SET_PREDICTION_DATA_LOADING = "SET_PREDICTION_DATA_LOADING"
export const SET_PROJECTION_DATA = "SET_PROJECTION_DATA"
export const SET_PROJECTION_DATA_LOADING = "SET_PROJECTION_DATA_LOADING"
export const SET_CONNECTOR_DATA = "SET_CONNECTOR_DATA"
export const SET_CONNECTOR_DATA_LOADING = "SET_CONNECTOR_DATA_LOADING"


export const setPredictionData = (data) => ({
  type : SET_PREDICTION_DATA,
  data
})

export const setIsPredictionDataLoading = (status) => ({
  type : SET_PREDICTION_DATA_LOADING,
  status
})

export const setProjectionData = (data) => ({
  type : SET_PROJECTION_DATA,
  data
})

export const setIsProjectionDataLoading = (status) => ({
  type : SET_PROJECTION_DATA_LOADING,
  status
})

export const setIndividualConnectorData = (data) => ({
  type : SET_CONNECTOR_DATA,
  data
})

export const setIsConnectorDataLoading = (status) => ({
  type : SET_CONNECTOR_DATA_LOADING,
  status
})


export function getPredictionData(filters, connector_id) {

  let start_date = moment(new Date()).startOf("day").toISOString();
  let end_date = moment(new Date()).endOf("day").add({ day: 365 }).toISOString()

  return async (dispatch) => {
    try {
      dispatch(setIsPredictionDataLoading(true));
      const res = await callApi(`api/results/predict-graph?connector_id=${connector_id}&start_date=${start_date}&end_date=${end_date}`, "post", {
        pageSize : filters?.pageSize,
        pageNum : filters?.pageNum
      });

      if (res && res.status === "Success") {
        dispatch(setPredictionData(res?.data));
        dispatch(setIsPredictionDataLoading(false));

      } else {
        errorMsg("Prediction data not loaded!");
      } 
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsPredictionDataLoading(false));
    }
  };
}

export function  getProjectionData(filters, connector_id) {

  let start_date = filters.start_date;
  // let start_date = new Date()  
  let end_date = filters.end_date;
  // let end_date = moment(new Date()).endOf("day").toISOString();

  return async (dispatch) => {
    try {
      dispatch(setIsProjectionDataLoading(true));
      const res = await callApi(`api/results/projection-data?start_date=${start_date}&end_date=${end_date}`
      , "post", {
        pageSize : filters?.pageSize,
        pageNum : filters?.pageNum,
        connectorId : connector_id
      });

      if (res && res.status === "Success") {
        dispatch(setProjectionData(res?.data));
        dispatch(setIsProjectionDataLoading(false));

      } else {
        errorMsg("Projection data not loaded!");
      } 
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsProjectionDataLoading(false));
    }
  };
}

export function getIndividualConnectorInfo(connector_id) {

  return async (dispatch) => {
    try {
      dispatch(setIsConnectorDataLoading(true));
      const res = await callApi(`api/results/connectors/${connector_id}/info`, "get");

      if (res && res.status === "Success") {
        dispatch(setIndividualConnectorData(res?.data));
        dispatch(setIsConnectorDataLoading(false));

      } else {
        errorMsg("Connector data not loaded!");
      } 
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsConnectorDataLoading(false));
    }
  };
}


