import {
    SET_DASHBOARD_DATA,
    SET_DASHBOARD_DATA_LOADING,
    SET_CONNECTOR_TYPE,
    SET_CONNECTOR_TYPE_LOADING,
    SET_STATE_CHANGE_LOADING,
    SET_CHANGED_CONNECTOR
} from './DashboardActions';

const initialState = {
    dashboardData : null, 
    isDashboardDataLoading : false,
    connectorTypeList : null,
    isConnectorTypeLoading : false,
    isStateChangeLoading : false,
    changedConnector : null
}

const dashboardReducer = (state = initialState, action={}) =>{
    switch (action.type) {
        case SET_DASHBOARD_DATA_LOADING:
            return {
                ...state,
                isDashboardDataLoading: action.status,
            }

        case SET_DASHBOARD_DATA :
            return {
                ...state,
                dashboardData : action.data
            }
        case SET_CONNECTOR_TYPE_LOADING :
            return {
                ...state,
                isConnectorTypeLoading : action.status
            }
        case SET_CONNECTOR_TYPE :
            return {
                ...state,
                connectorTypeList : action.data
            }
        case SET_STATE_CHANGE_LOADING :
            return {
                ...state,
                isStateChangeLoading : action.status
            }
        case SET_CHANGED_CONNECTOR :
            return {
                ...state,
                changedConnector : action.data
            }
        default:
            return state;
    }
}

/**selectors */
export const getDashboardDataLoaded = state => state.dashboard.isDashboardDataLoading
export const getDashboardDataResult = state => state.dashboard.dashboardData
export const getConnectorTypeLoaded = state => state.dashboard.isConnectorTypeLoading
export const getConnectorTypeResult = state => state.dashboard.connectorTypeList
export const getStateChangeLoading = state => state.dashboard.isStateChangeLoading
export const getChangedConnector = state => state.dashboard.changedConnector


export default dashboardReducer;
