import "./statusReport.css";
import ConfidenceInterval from "components/Charts/ConfidenceInterval";
import DefaultModal from "components/DefaultModal/DefaultModal";
import { Chart, registerables } from "chart.js";
import React, { useEffect,useRef, useState } from "react";
import { RiseLoader } from "react-spinners";
import { CircularProgress } from "@mui/material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import callApi from "../../util/apiCaller";
import moment from "moment";
import ConnectorResistance from "components/Charts/ConnectorResistance";
import DateButton from "./Components/DateButton";
import styled from "styled-components";
import PredictChart from "components/Charts/PredictChart";
import { getIndividualConnectorInfo, getPredictionData, getProjectionData } from "store/ConnectorPage/ConnectorActions";
import { getConnectorDataResult, getPredictionDataResult, getProjectionDataResult } from "store/ConnectorPage/ConnectorReducer";
import { useSelector, useDispatch } from "react-redux";
import { dateDiffCalculator } from "components/Charts/utils";
const StyledPredictButton = styled.button`
  &:hover {
    background: #558194;
    color: #ffffff;
  }
`;
const StyledRefreshButton = styled.div`
  cursor: pointer;
`;

Chart.register(...registerables);

const colorMapper = {
  "normal": "#60c610",
  "field": "#efbc0f",
  "cross": "#f2a541",
  "crimp": "#f04b4b",
};

const StatusReport = ({ id, selectedDevice }) => {
  console.log('selectedDevice1', selectedDevice)
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingPredictChart, setLoadingPredictChart] = useState(true)
  const [selectDeviceModal, setSelectDeviceModal] = useState(false);

  const individualConnectorData = useSelector(state => getConnectorDataResult(state))
  const projectionData = useSelector((state) => getProjectionDataResult(state))
  const predictionData = useSelector((state) => getPredictionDataResult(state))

  console.log(projectionData, "counter", predictionData)

  
  const [filters, setFilters] = useState({
    end_date: moment().startOf("day").subtract({ day: 1 }),
    start_date: moment().endOf("day").subtract({ day: 30 }),
  });

  const fetchDeviceData = async (_id) => {
    try {
      setIsLoading(true);
      const res = await callApi(
        `deviceinputdatas/list?device_id=${_id}`,
        "post",
        {
          filters: {
            start_date: filters.start_date,
            end_date: filters.end_date,
          },
          pageNum: 1,
          pageSize: 500000,
        }
      );
      if (res && res.data) {
        const { list } = res.data;
        list.sort(function (a, b) {
          return new Date(a.datetime) - new Date(b.datetime);
        });
        // setData(list);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(async () => {
    await fetchDeviceData(id);
  }, [id, filters]);

  useEffect(() => {
    dispatch(getProjectionData({pageSize : 1000, pageNum : 1, start_date : filters.start_date, end_date : filters.end_date}, id))
    dispatch(getPredictionData({pageSize : 1000, pageNum : 1}, id))
  }, [id, selectedDevice, filters])

  const setPredictChart = () =>{
    setTimeout(() => {
      setLoadingPredictChart(false)
    }, 3000)
  }

  const openSelectDeviceModal = () => {
    setPredictChart()
    setSelectDeviceModal(true);
  }
  const closeSelectDeviceModal = () => {
      setSelectDeviceModal(false);
  };
  
  const handleKPIDateChange = (_, picker) => {
    const newFilters = { ...filters };
    newFilters.start_date = picker.startDate;
    newFilters.end_date = picker.endDate;
    console.log("newFilters", newFilters);
    setFilters(newFilters);
  };

  const handleRefresh = (e) => {
    const newFilters = { ...filters };
    newFilters.start_date = moment().endOf("day").subtract({ day: 30 });
    newFilters.end_date = moment().startOf("day");
    setFilters(newFilters);
  };

  const venuestart = filters.start_date.format("DD-MM-YYYY");
  const venueend = filters.end_date.format("DD-MM-YYYY");
  let kpidatelabel = `${venuestart} - ${venueend} `;
  if (venuestart === venueend) {
    kpidatelabel = venuestart;
  }

  const getStatics = () => {
    if (selectedDevice?.category === "Normal") {
      return (
        <div className="flex-item-right mb-3 mt-3" style={{background:"#F4F6F8"}}>
          <h5>Time To Failure</h5>
          <p style={{textAlign: "center"}}>{selectedDevice?.time_to_failure}</p>
        </div>
      );
    }
    if (selectedDevice?.category === "Faulty") {
      return (
        <div className="flex-item-right mb-3 mt-3" style={{background:"#F4F6F8"}}>
          <h5>Intervention Date</h5>
          <p style={{textAlign: "center"}}>{selectedDevice?.intervention_date}</p>
        </div>
      );
    }
    if (selectedDevice?.category === "Hazardous") {
      return (
        <div className="flex-item-right mb-3 mt-3" style={{background:"#F4F6F8", paddingLeft: "20px"}}>
          <h5>Recommended Action</h5>
          <p style={{textAlign: "center"}}>{selectedDevice?.recommended_action}</p>
        </div>
      );
    }
  }

  if (isLoading) {
    return (
      <div className="performance_wrap">
        <div>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="grid-button button-margin">
        <DateButton filters={filters} setFilters={setFilters} />
        {/* Date-input */}
        <div className="date-div">
          {/* <label for='input-start-date' className='date-input-label'>Start Date</label> */}

          <img
            src="https://ik.imagekit.io/rzlzf40okya/smarthelio/calendar.svg"
            className="calendar_icon"
          />
          <DateRangePicker
            initialSettings={{
              startDate: filters.start_date,
              endDate: filters.end_date,
              autoApply: true,
              maxDate: moment().endOf("day").subtract({ day: 1 }),
            }}
            onApply={handleKPIDateChange}
          >
            <input
              value={kpidatelabel}
              className="date-input start-date center-position remove-bottom-margin"
              placeholder="Start Date"
            />
          </DateRangePicker>
          <img
            src="https://ik.imagekit.io/rzlzf40okya/smarthelio/chevron_big_down.svg"
            className="down_icon"
          />
        </div>
        <StyledRefreshButton onClick={() => openSelectDeviceModal()} className="center-position remove-bottom-margin refresh_button">
            <h4> Predict</h4>
          </StyledRefreshButton>
      </div>
      <DefaultModal
        showFooter={false}
        size='lg'
        className='status_modal'
        title= 'Connector Health Prediction'
        // icon='https://ik.imagekit.io/rzlzf40okya/smarthelio/Prediction-icon.svg'
        icon={<img src="https://ik.imagekit.io/rzlzf40okya/smarthelio/Prediction-icon.svg"/>}
        show={selectDeviceModal}
        onHide={() => closeSelectDeviceModal(false)}
        >
          {loadingPredictChart ?
           <div style={{height : "100%", display:"flex", justifyContent :"center", alignItems:"center"}}>
             <RiseLoader size={8} color={"#558194"} /> 
           </div>
            :
          projectionData && predictionData && <PredictChart
            projectionData={projectionData?.list}
            bgColor={"#f0f4f4"}
            predictionData = {predictionData}
          />
          }
          
        </DefaultModal>
      <div className="grid-class">
        <div style={{ flex: "0 0 70%" }}>
          <div className="status-box">
            <div
              style={{
                backgroundColor: "#fff",
                textAlign: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className="center-position remove-bottom-margin">
                Connector Resistance
              </p>
              <StyledPredictButton
                className="btn-predict"
                onClick={(e) => handleRefresh(e)}
              >
                Refresh
              </StyledPredictButton>
            </div>
            <hr style={{ backgroundColor: "#0009" }} />
            <div style={{ height: "82%" }}>
              {projectionData&& <ConnectorResistance
                projectionData={projectionData?.list}
                bgColor={"#f0f4f4"}
                filters={filters}
              />
              }
            </div>
          </div>
        </div>
        <div style={{ flex: "0 0 27%" }}>
          <div className="p-0" style={{height:"85%"}}>
            <div className="mb-3"style={{ textAlign: "center",padding:"0px" }}>
              <h5 className="interval_class">Connector Statistics</h5>
              {/* <hr style={{ backgroundColor: "#0009" }} /> */}
              <div className="border-bottom"></div>
            </div>
            {/* <div
              style={{
                height: "60%",
                marginBottom: 40,
                marginTop: 24,
                position: "relative",
              }}
            >
              <ConfidenceInterval
                bg={"white"}
                interval={selectedDevice?.confidence_interval}
              />
            </div> */}
            <div className="connector_data">
              <div className="flex-item-right">
              <h5>Status</h5>
             
              <div
                style={{ backgroundColor: colorMapper[selectedDevice?.fault_status] }}
                className="btn-projection"
              >
                {selectedDevice?.fault_status}
              </div>
              </div>
           
              {getStatics()}

             
              <div className="flex-item-right" style={{marginTop : "5%"}}>
              <h5>Age Of Connectors</h5>
              <p>{selectedDevice && dateDiffCalculator(selectedDevice?.date_of_commission, new Date()).toFixed(0)} days</p>
              </div>
              <div className="flex-item-right" style={{marginTop : "5%"}}>
              <h5>{selectedDevice && selectedDevice?.current_status == "normal" ? "Time to Failure" : "Intervention Date"}</h5>
              <p>{selectedDevice && selectedDevice?.current_status == "normal" ? `${selectedDevice?.time_to_failure.toFixed(0)} days` : selectedDevice?.intervention_date.slice(0, 10)}</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      {/* <OuterBox height={360}>
        <HealthReport />
      </OuterBox> */}
    </>
  );
};

export default StatusReport;
