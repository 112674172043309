import { hover } from "@testing-library/user-event/dist/hover";
import { color } from "d3";
import { Item } from "devextreme-react/accordion";
import React from "react";
import { backgroundColor } from "styled-system";
import "./tables.css";
const Tables = ({ columnsData, rowData,}) => {
  const getCap = (str) => {
    return str.toUpperCase();
  };

  return (
    <div>
      <table className="table table-hover table-bg-color">
        <thead>
          <tr className="tr_items">
            {columnsData.map((item) => {
              return <th className="th_item">{getCap(item.header)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {rowData.map((row) => {
            return (
              <tr className="tr_items_list">
                {columnsData.map((col) => (
                  <td className="td_items">{row[col.field]}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Tables;
