import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Button from "../Button/Button"
import "./defaultModal.css"
import { Icon } from '@material-ui/core';
function DefaultModal({
    show,
    size,
    centered,
    title,
    onHide,
    children,
    handleBtnClick,
    className,
    showHeader,
    showFooter,
    fullScreen,
    icon,
    submitText = "Submit"
}) {
  return (
    <Modal
        show={show}
        size={size}
        centered={centered}
        onHide={onHide}
        className={className}
        fullscreen={fullScreen}
    >
        {showHeader && (
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                   <span style={{marginRight:"7px"}}> {icon}</span>{title}
                </Modal.Title>
            </Modal.Header>
        )}
        <Modal.Body>
            {children}
        </Modal.Body>
        {showFooter && (
            <Modal.Footer>
                <div className="m-footer">
                <Button m={"10px 10px 10px 0px"} color={"#ff8800"} bg={"white"} onClick={() => handleBtnClick("left")}>
                    CANCEL
                </Button>
                <Button m={"10px 0px 10px 10px"} onClick={() => handleBtnClick("right")}>
                   {submitText}
                </Button>
                </div>
            </Modal.Footer>
        )}
    </Modal>
  )
}

DefaultModal.propTypes = {
    show: PropTypes.bool.isRequired,
    size: PropTypes.string,
    centered: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    handleBtnClick: PropTypes.func,
    className: PropTypes.string,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    fullScreen: PropTypes.bool,
};

DefaultModal.defaultProps = {
    size: 'sm', // [lg, sm]
    centered: true,
    title: 'Title',
    icon:'image',
    handleBtnClick: undefined,
    className: '',
    showHeader: true,
    showFooter: true,
    fullScreen: false,
};

export default DefaultModal;
