export const USER_ID = "userId";
export const JWT_TOKEN = "token";

export const classificationConstants = {
    normal_initial : "normal_initial",
normal_end : "normal_end",
faulty_initial : "faulty_initial",
faulty_end : "faulty_end",
hazardous_initial : "hazardous_initially",
hazardous_end : "hazardous_end",
color_normal : "#60C610",
color_faulty : "#F2A541",
color_hazardous : "#F04B4B",
}

export const MIN_THRESHOLD_VALUE = .85

