import React from 'react';
import notFoundImg from '../../assets/404Img.svg';
import './notFound.css';

function NotFoundPage() {
  return (
    <div className="not_found_wrap">
      <p className="not_found_text">
        Error 404 - Page Not Found
      </p>
      <img
        src={notFoundImg}
        alt="404-img"
        className="not_found_img"
      />
    </div>
  )
}

export default NotFoundPage;
