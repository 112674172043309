import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import callApi from "util/apiCaller";
import { successMsg, errorMsg } from "util/toast";
import downloadApi from "util/apiDownloader";
import report from "../../../constants/reports/Connector_Health_Report.pdf";

const StyledH1Block = styled.h1`
  justify-content: center;
`;
const REPORT_TYPE_ACTION_REPORT_NORMAL = "normal";
const REPORT_TYPE_ACTION_REPORT_FAULTY = "faulty";
const REPORT_TYPE_ACTION_REPORT_HAZARDOUS = "hazardous";

const HealthReport = ({ selectedReportType }) =>{
    const [email, setUserData] = useState("");
    const [report_type, setReportType] = useState("");
    const [isSendingEmail, setSendEmailStatus] = useState(false);

  useEffect(() =>{
    console.log('selectedReportType', selectedReportType)
    if (selectedReportType && selectedReportType === 1) {
      setReportType(REPORT_TYPE_ACTION_REPORT_NORMAL);
    } else if (selectedReportType && selectedReportType === 2) {
      setReportType(REPORT_TYPE_ACTION_REPORT_FAULTY);
    } else {
      setReportType(REPORT_TYPE_ACTION_REPORT_HAZARDOUS);
    }
  },[selectedReportType]);

    const openPdfFile = async (e) => {
      if (e) {
        e.preventDefault();
      }
      try {
        setSendEmailStatus(true);
        const res = await callApi(
          `email/pdf?email=${email}&report_type=${report_type}`,
          "get"
        );
        if (res && res.status === "Success") {
          successMsg("Send report successfully!")
          setUserData("");
        } else {
          errorMsg("Something went wrong!!")
        }
        setSendEmailStatus(false);
      } catch (err) {
        errorMsg("Something went wrong!!")
        setSendEmailStatus(false);
      }
    };

  const downloadBook = (e) => {
    if (e) {
      e.preventDefault();
    }
    let name = ``;
    let file = ``;
    if (report_type) {
      name = `Connector Health Report.pdf`;
      file = report
    }
    downloadApi(name, file, 'get').then(res => {
      // do something
    })
  }
    return (
    <div className='health_report'>
      <StyledH1Block>Send Connector Health Report</StyledH1Block>
      {/* <h5>Choose the Medium.</h5> */}
      {/* <div className='d-flex'>
       <div className='email_box'>
        <p>Email Address</p>
       </div>
       <div className='whatsup_box'>
        <p><i className="fa fa-whatsapp" aria-hidden="true"></i>Whatsapp Number</p>
       </div>
      </div> */}
      <input
         className='health_input'
          value={email}
          onChange={(e) => setUserData(e.target.value)} type="text"
          placeholder='Enter Email Address'
          />
        <div className='d-flex justify-content-center mt-4'>
          <button className='gray_but' style={{marginRight:"20px"}}  onClick={(e) => openPdfFile(e)}>
            {isSendingEmail ? "Sending Report" : "Send Report"}
          </button>

            <button className='gray_but' onClick={(e) => downloadBook(e)}>
              <img src="https://ik.imagekit.io/rzlzf40okya/smarthelio/noun-download.svg"/>
              Download Now
            </button>
        </div>
    </div>
    )
}

export default HealthReport;
