import React from 'react'
import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  system,
} from "styled-system";

const Input = styled.input.attrs(props => ({
  type: props.type ? props.type : 'text',
}))`
${space};
${color};
${typography};
${layout};
${flexbox};
${grid};
${background};
${border};
${position};
${shadow};

padding : ${props => props.p ? props.p : "6px 12px"};
border : ${props => props.border ? props.border : "1px solid rgba(45, 35, 46, 0.2)"};
border-radius : ${props => props.borderRadius ? props.borderRadius : "8px"};
font-size :  ${props => props.fontSize ? props.fontSize : "13px"};
width : ${props => props.width ? props.width : "100%"};
outline : none;
    
`

export default Input