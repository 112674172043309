import React from "react";
import Card from 'react-bootstrap/Card';
import actionDashboard from "../../content/ActionDashboardPlants.json";
import ActionDashboardTable from '../../components/ActionDashboardTable/ActionDashboardTable';
import './actionDashboard.css';

const ActionDashboard = () => {
    return (
        <>
            <div className='action-connecter' style={{ marginTop: "7rem", }}>
                <p>Connectors</p>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        {actionDashboard.map(item => (
                            <div className="col-md-6">
                                <Card className="plants-cards">
                                    <Card.Header>{item.title}</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <p>{item.size}</p>
                                            <p>{item.location}</p>
                                            <p>{item.devices}</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-6">
                    <ActionDashboardTable />
                </div>
            </div>
        </>
    )
}

export default ActionDashboard;