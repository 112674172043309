import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-toastify/dist/ReactToastify.css";

import {
  AUTHENTICATED,
  AUTHENTICATNG,
  NOT_AUTHENTICATED,
} from "constants/enums/authEnums";
import React, { useEffect } from "react";
import { getCompany, getUser } from "store/App/AppReducer";
import {
  getCompanyDetails,
  getPlants,
  setAuthState,
  setUser,
} from "store/App/AppActions";
import { useDispatch, useSelector } from "react-redux";

import { APP_TOKEN } from "constants/enums/appEnums";
import AppRoutes from "./routes/AppRoutes";
import JwtDecode from "jwt-decode";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// css

function App() {
  const token = localStorage.getItem(APP_TOKEN);
  const dispatch = useDispatch();
  const user = useSelector((state) => getUser(state));
  const company = useSelector((state) => getCompany(state));

  useEffect(() => {
    try {
      dispatch(setAuthState(AUTHENTICATNG));
      // get user from the payload of jwt token
      if (token) {
        const tempUser = JwtDecode(token);
        dispatch(setUser(tempUser));
        dispatch(setAuthState(AUTHENTICATED));
      } else {
        dispatch(setAuthState(NOT_AUTHENTICATED));
      }
    } catch (err) {
      dispatch(setAuthState(NOT_AUTHENTICATED));
    }
  }, []);

  useEffect(() => {
    // get Company details
    if (user && user.contact_email) {
      dispatch(getCompanyDetails(`manager_email=${user.contact_email}`));
    }
  }, [user]);

  useEffect(() => {
    // get alll the plants related to a company
    if (company) {
      dispatch(getPlants(`company_id=${company.company_id}`));
    }
  }, [company]);

  return (
    <>
      <Router>
        <div>
          <AppRoutes />
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
