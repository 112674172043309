import React, { useState } from "react";

const Footer = () => {
  return (
    <div className="footer_sec">
      <div className="connecter-footer">
        <p>#Sherpa Project | Powered by ©SmartHelio 2022</p>
      </div>
    </div>
  );
};

export default Footer;
