import './resetPasswordPage.css';

import React, {useState} from 'react';

import Brand from '../../assets/brand-img.png'
import {Helmet} from "react-helmet";
import LoginImg from '../../assets/login-bg-1.png'
import callApi from "../../util/apiCaller";
import {toast} from 'react-toastify';
import {useLocation} from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPasswordPage = () => {
    const query = useQuery();
    const resetToken = query.get('reset_token');

    const [user, setUserData] = useState({
        password: '',
        confirmPassword: ''
    });

    const handleChangeUser = (keyName, keyValue) => {
        const tempUser = {...user};
        tempUser[keyName] = keyValue;
        setUserData(tempUser);
    }

    const notify = (data) => {
        toast(data)
    }

    const handleForgotPasswordAPI = () => {
        const { password } = user;
        if (!resetToken) {
            notify('No reset token is present!');
            return;
        }
        callApi(`auth/user/reset/${resetToken}`, 'post', {
            user: password.trim(),
        }).then(res => {
            if (res.status === 'Success') {
                notify(res.data.message);
                setTimeout(() => {
                    window.location = "/login";
                }, 1000);
            } else {
                notify(res.data.message);
            }
        }).catch(() => {
            notify('Something went wrong!');
        });
    };

    const validatePassword = () => {
        const res = {
            validated: true,
            text: '',
        }
        const { password, confirmPassword } = user;
        if (password.trim().length < 8) {
            res.validated = false;
            res.text = 'Password length must be greater than or equal to 8!';
        } else if (password.trim() !== confirmPassword.trim()) {
            res.validated = false;
            res.text = 'Password and Confirm Password do not match!';
        }
        return res;
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const validate = validatePassword();
        if (!validate.validated) {
            notify(validate.text);
            return;
        }
        handleForgotPasswordAPI();
    }

    return (
        <>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Reset Password | SmartHelio</title>
            </Helmet>
            <section className='login-section'>
                <div className="img-box">
                    <img src={LoginImg} alt="LoginImg"/>
                </div>
                <div className="content-box">
                    <div className="form-box">
                        <img src={Brand} className='brand-img' alt=""/>
                        <h2>Reset Password </h2>
                        <form onSubmit={handleSubmit}>
                            <div className="input-box">
                                <span>Password*</span>
                                <input
                                    value={user.password}
                                    onChange={(e) => handleChangeUser("password", e.target.value)}
                                    type="password"
                                />
                            </div>
                            <div className="input-box">
                                <span>Confirm Password*</span>
                                <input
                                    value={user.confirmPassword}
                                    onChange={(e) => handleChangeUser("confirmPassword", e.target.value)}
                                    type="password"
                                />
                            </div>
                            <div className="reset-btn-box">
                                <button
                                    type="submit"
                                    disabled={!user.password.trim() || !user.confirmPassword.trim()}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPasswordPage
